import { shouldComponentUpdate as shouldPureComponentUpdate } from 'react-immutable-render-mixin';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import MatterForm from './MatterForm';
import Immutable from 'immutable';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import MatterID from './MatterID';

export default class Matter extends Component {
  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    categories: PropTypes.instanceOf(Immutable.List).isRequired,
    client: PropTypes.instanceOf(Immutable.Map).isRequired,
    createCategoryMatter: PropTypes.func.isRequired,
    createMatter: PropTypes.func.isRequired,
    deleteMatter: PropTypes.func.isRequired,
    downloadExcelReport: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    matter: PropTypes.instanceOf(Immutable.Map).isRequired,
    matterList: PropTypes.instanceOf(Immutable.List).isRequired,
    removeCategory: PropTypes.func.isRequired,
    selectedMatterTab: PropTypes.string.isRequired,
    selectMatter: PropTypes.func.isRequired,
    selectMatterTab: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    toggleClientMatter: PropTypes.func.isRequired,
    toggleSyncPanel: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    viewCategory: PropTypes.func.isRequired,
    viewCategoryMatter: PropTypes.func.isRequired,
    viewFamily: PropTypes.func.isRequired,
    sweepMatter: PropTypes.func.isRequired,
    checkEspace: PropTypes.func.isRequired,
    fetchPatentFamily: PropTypes.func.isRequired,
    addFirstMatterTwoWayRelation: PropTypes.func.isRequired,
    addMatterTwoWayRelation: PropTypes.func.isRequired,
    fetchFamily: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  shouldComponentUpdate = shouldPureComponentUpdate;

  onChange(changedFields) {
    this.props.updateMatter({
      id: this.props.matter.get('id'),
      ...changedFields
    }, this.props.matter.get('matterNumber'));
  }

  renderHeader() {
    return (
      <MatterID
        client={this.props.client}
        createCategoryMatter={this.props.createCategoryMatter}
        createMatter={this.props.createMatter}
        deleteMatter={this.props.deleteMatter}
        downloadExcelReport={this.props.downloadExcelReport}
        features={this.props.features}
        fileMatter={this.props.fileMatter}
        logAction={this.props.logAction}
        matter={this.props.matter}
        matterList={this.props.matterList}
        removeCategory={this.props.removeCategory}
        selectMatter={this.props.selectMatter}
        setFileReminder={this.props.setFileReminder}
        updateMatter={this.props.updateMatter}
        viewCategory={this.props.viewCategory}
        viewCategoryMatter={this.props.viewCategoryMatter}
        viewFamily={this.props.viewFamily}
      />
    );
  }

  isExpanded() {
    return !!(this.props.expanded && this.props.matter.get('id'));
  }

  getRecord() {
    const { client, matter } = this.props;
    return matter.get('entitySize')
      ? matter
      : matter.set('entitySize', client.get('entitySize'));
  }

  renderContent() {
    const record = this.getRecord();
    return this.isExpanded() && (
      <MatterForm
        record={record}
        features={this.props.features}
        client={this.props.client}
        onChange={this.onChange}
        logAction={this.props.logAction}
        fetchPatentFamily={this.props.fetchPatentFamily}
        checkEspace={this.props.checkEspace}
        selectedTab={this.props.selectedMatterTab}
        onSelectTab={this.props.selectMatterTab}
        updateMatter={this.props.updateMatter}
        selectMatter={this.props.selectMatter}
        partnerEmail={this.props.client.get('partnerEmail')}
        categories={this.props.categories}
        matterList={this.props.matterList}
        createMatter={this.props.createMatter}
        addNotification={this.props.addNotification}
        toggleSyncPanel={this.props.toggleSyncPanel}
        sweepMatter={this.props.sweepMatter}
        addFirstMatterTwoWayRelation={this.props.addFirstMatterTwoWayRelation}
        addMatterTwoWayRelation={this.props.addMatterTwoWayRelation}
        fetchFamily={this.props.fetchFamily}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel
        id='matter-panel'
        styleCollapse={{ padding: '6px 0' }}
        expanded={this.isExpanded()}
        header={this.renderHeader()}
        onToggle={this.props.toggleClientMatter}
      >
        {this.renderContent()}
      </CollapsiblePanel>
    );
  }
}
