import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import { isCategoryMatter, getCategoryFromMatterNumber } from '../../utils/categories';
import { isRecentMonths } from '../../utils/date';
import HelpIcon from '../shared/HelpIcon';
import TableList, { Column } from '../shared/TableList';
import TodoApproved from './TodoApproved';
import TodoField from './TodoField';
import TodoFile from './TodoFile';
import TodoIDS from './TodoIDS';
import TodoPairStatus from './TodoPairStatus';
import { getTodoRecord } from '../../utils/get-todo-record';
import TodosMigration from './TodosMigration';
import TodosPages from './TodosPages';
import TodosReports from './TodosReports';
import TodoStatement from './TodoStatement';
import TodoTiming from './TodoTiming';
import TodoUpload from './TodoUpload';
import TodoUrgent from './TodoUrgent';
import Tooltip from '../shared/Tooltip';
import { Button } from 'react-bootstrap';

export default class TodosList extends Component {
  static propTypes = {
    fetchAttorneyIfNeeded: PropTypes.func.isRequired,
    attorneys: PropTypes.instanceOf(Immutable.Map).isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    subsetIDSEnabled: PropTypes.bool.isRequired,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    activePage: PropTypes.number,
    authToken: PropTypes.string.isRequired,
    checkConsistency: PropTypes.func.isRequired,
    clearConsistency: PropTypes.func.isRequired,
    clients: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired,
    fileMatter: PropTypes.func.isRequired,
    firmName: PropTypes.string,
    loadSyncOverlap: PropTypes.func.isRequired,
    loginEmail: PropTypes.string.isRequired,
    matterConsistency: PropTypes.instanceOf(Immutable.Map).isRequired,
    matters: PropTypes.instanceOf(Immutable.Map).isRequired,
    pageSize: PropTypes.number,
    selectClientAndMatter: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
    selectedMatterId: PropTypes.string,
    selectPage: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    show: PropTypes.string,
    sortDirection: PropTypes.number,
    sortField: PropTypes.string,
    sortTodos: PropTypes.func.isRequired,
    term: PropTypes.string,
    todoList: PropTypes.instanceOf(Immutable.List).isRequired,
    updateMatter: PropTypes.func.isRequired,
    updatePairStatus: PropTypes.func.isRequired,
    uploadAutoGoldData: PropTypes.func.isRequired,
    uploadTwoWayMigration: PropTypes.func.isRequired,
    pairStatus: PropTypes.object.isRequired,
    sortPairStatus: PropTypes.func.isRequired,
    viewCategoryMatter: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    filterType: PropTypes.string.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    entities: PropTypes.instanceOf(Immutable.Map).isRequired,
    updateMatterFromPeds: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    onClickAssistant: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired,
    updateMatterPairStatus: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  getAllItems() {
    const {
      todoList,
      matters,
      matterConsistency,
      clients
    } = this.props;

    return todoList.map(matterId => {
      const matter = matters.get(matterId);
      if (!matter) {
        return null;
      }

      const client = clients.get(matter.get('clientId'));
      if (!client) {
        return null;
      }

      const applicationNumber = (matter.get('applicationNumber') || '').replace(/[\D]/g, '');

      let pairStatus = '';
      if (applicationNumber) {
        const todoPairStatus = this.props.pairStatus[applicationNumber];
        if (todoPairStatus) {
          if (todoPairStatus.status) {
            pairStatus = todoPairStatus.status;
          } else if (todoPairStatus.failed) {
            pairStatus = '0';
          }
        }
      }

      return getTodoRecord(matter, client, {
        consistency: matterConsistency.get(matter.get('id')),
        pairStatus
      });
    }).filter(item => {
      if (!item) {
        return false;
      }
      if (this.props.show === 'empty' && (item.uncited || item.cited || item.isClosed())) {
        return false;
      }
      if (this.props.show === 'todo' && item.uncited === 0) {
        return false;
      }
      return true;
    });
  }

  getSortData(sortField, todo) {
    if (sortField === 'fileReminder') {
      return todo.getFiledSort();
    }
    if (sortField === 'alternateId') {
      const value = todo.get(sortField).toLowerCase();
      return value || 'zzzzzzzzzzzzzz';
    }
    return todo.get(sortField);
  }

  getSortedItems() {
    const {
      sortField,
      sortDirection
    } = this.props;

    if (!sortField || !sortDirection) {
      return this.getAllItems();
    }

    return this.getAllItems().sort((a, b) => {
      const vA = this.getSortData(sortField, a);
      const vB = this.getSortData(sortField, b);
      return vA === vB
        ? 0
        : vA > vB
          ? sortDirection
          : -sortDirection;
    });
  }

  getPagesData() {
    const sortedItems = this.getSortedItems();

    if (this.props.pageSize === 0) {
      return [sortedItems];
    }

    const pageCount = Math.ceil(sortedItems.size / this.props.pageSize);

    if (pageCount <= 1) {
      return [sortedItems];
    }

    const pages = [];

    for (let i = 0; i < pageCount; i++) {
      pages.push(
        sortedItems.slice(i * this.props.pageSize, (i + 1) * this.props.pageSize)
      );
    }

    return pages;
  }

  getActivePage(pages) {
    if (pages.length <= 1) {
      return 1;
    }

    let activePage = this.props.activePage;

    if (activePage === null) {
      if (this.props.selectedMatterId && pages.length > 1) {
        const pageIndex = pages.findIndex(page => page.find(todo => todo.get('id') === this.props.selectedMatterId));
        return pageIndex === -1 ? 1 : pageIndex + 1;
      }
      return 1;
    }

    while (!pages[activePage - 1] && activePage > 1) {
      activePage--;
    }

    return Math.max(activePage, 1);
  }

  getRenderData() {
    const pages = this.getPagesData();
    const activePage = this.getActivePage(pages);
    const itemsToRender = pages[activePage - 1] || Immutable.List();

    return {
      pageCount: pages.length,
      itemsToRender,
      activePage
    };
  }

  onUpload(todo, url) {
    this.setState({
      upload: {
        todo,
        url
      }
    });
  }

  onClickAssistant() {
    this.props.onClickAssistant();
  }

  onClickSortFiled() {
    this.props.sortTodos('fileReminder');
  }

  onClickSortClient() {
    this.props.sortTodos('clientNumber');
  }

  onClickSortMatter() {
    this.props.sortTodos('matterNumber');
  }

  onClickSortPairStatus() {
    this.props.sortPairStatus(this.getAllItems());
  }

  onClickSortUrgent() {
    this.props.sortTodos('urgent');
  }

  onClickSortAlternate() {
    this.props.sortTodos('alternateId');
  }

  onClickSortApproved() {
    this.props.sortTodos('approved');
  }

  onClickSortCited() {
    this.props.sortTodos('cited');
  }

  onClickSortUncited() {
    this.props.sortTodos('uncited');
  }

  onClickRow(row) {
    if (isCategoryMatter(row.matterNumber)) {
      this.props.viewCategoryMatter(getCategoryFromMatterNumber(row.matterNumber));
    } else {
      this.props.selectClientAndMatter(row);
    }
  }

  checkUncitedDocuments(todo) {
    const matter = this.props.matters.get(todo.get('matterId'));
    if (matter) {
      return this.props.fetchDocumentsIfNeeded(matter).then(() => {
        const docNumbers = matter.get('uncitedArt', Immutable.List());
        const clientDocs = this.props.documents.getIn([matter.get('clientNumber')], Immutable.Map());
        return docNumbers.map(docNumber => clientDocs.get(docNumber))
          .some(doc => !isRecentMonths(doc.get('createdTime'), 3));
      });
    }
  }

  renderSort(field, title, onClick) {
    const className = field === this.props.sortField && this.props.sortDirection
      ? `fa fa-sort-amount-${this.props.sortDirection === 1 ? 'asc' : 'desc'}`
      : 'fa fa-sort';
    return (
      <a style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={onClick}>
        <span style={{ textDecoration: 'underline' }}>{title}</span> &nbsp;
        <span className={className} />
      </a>
    );
  }

  renderTable(rows) {
    const titleFiled = (
      <Tooltip tip='Marks references, that have complete data, as cited and generates a SyncIDS invoice.'>
        Cited?
      </Tooltip>
    );

    const iconApproved = (
      <HelpIcon
        gmIcon='approval'
        className='text-warning'
        styleIcon={{ fontSize: '20px', position: 'relative', top: '3px' }}
        help='Indicates that any review process is over. IDS has been approved for filing.'
      />
    );

    const sortFiled = this.renderSort('fileReminder', titleFiled, this.onClickSortFiled);
    const sortClient = this.renderSort('clientNumber', 'Client/BU ID', this.onClickSortClient);
    const sortMatter = this.renderSort('matterNumber', 'Matter ID', this.onClickSortMatter);
    const sortPairStatus = this.renderSort('pairStatus', 'PAIR', this.onClickSortPairStatus);
    const sortUrgent = this.renderSort('urgent', 'Urgent', this.onClickSortUrgent);
    const sortAlternate = this.renderSort('alternateId', 'Alternate ID', this.onClickSortAlternate);
    const sortApproved = this.renderSort('approved', iconApproved, this.onClickSortApproved);
    const sortCited = this.renderSort('cited', 'Cited', this.onClickSortCited);
    const sortUncited = this.renderSort('uncited', 'Uncited', this.onClickSortUncited);

    const file = (
      <Column style={{ width: '5em' }} title={sortFiled}>
        <TodoFile
          visibleDocuments={this.props.visibleDocuments}
          subsetIDSEnabled={this.props.subsetIDSEnabled}
          features={this.props.features}
          logAction={this.props.logAction}
          setFileReminder={this.props.setFileReminder}
          fileMatter={this.props.fileMatter}
        />
      </Column>
    );

    const ids = (
      <Column
        style={{ width: '2.5em' }} title={(
          <Tooltip tip='Click folder to generate IDS with or without the references. Uncited references with all required data fulfilled will flow to IDS form.'>
            IDS
          </Tooltip>
        )}
      >
        <TodoIDS
          callApi={this.props.callApi}
          features={this.props.features}
          selectedMatterId={this.props.selectedMatterId}
          visibleDocuments={this.props.visibleDocuments}
          subsetIDSEnabled={this.props.subsetIDSEnabled}
          setFileReminder={this.props.setFileReminder}
          checkConsistency={this.props.checkConsistency}
          clearConsistency={this.props.clearConsistency}
          updateMatterFromPeds={this.props.updateMatterFromPeds}
          onUpload={this.onUpload}
          showUpload={this.props.features.uploader}
          authToken={this.props.authToken}
        />
      </Column>
    );

    const client = (
      <Column title={sortClient}>
        <TodoField field='clientNumber' />
      </Column>
    );

    const matter = (
      <Column title={sortMatter}>
        <TodoField field='matterNumber' />
      </Column>
    );

    const cited = (
      <Column title={sortCited} style={{ width: '5em' }}>
        <TodoField field='cited' />
      </Column>
    );

    const uncited = (
      <Column title={sortUncited} style={{ width: '6em' }}>
        <TodoField field='uncited' />
      </Column>
    );

    const timing = (
      <Column title='Timing' style={{ width: '6em' }}>
        <TodoTiming updateMatter={this.props.updateMatter} />
      </Column>
    );

    const statement = (
      <Column title='Statement' style={{ width: '6.5em' }}>
        <TodoStatement
          checkUncitedDocuments={this.checkUncitedDocuments}
          features={this.props.features}
          updateMatter={this.props.updateMatter}
        />
      </Column>
    );

    const ptoFee = (
      <Column title='PTO Fee' style={{ width: '5em', textAlign: 'center' }}>
        <TodoField field='ptoFee' />
      </Column>
    );

    const approved = (
      <Column title={sortApproved} style={{ width: '3.5em', textAlign: 'center' }}>
        <TodoApproved
          fetchAttorneyIfNeeded={this.props.fetchAttorneyIfNeeded}
          attorneys={this.props.attorneys}
          checkConsistency={this.props.checkConsistency}
          clearConsistency={this.props.clearConsistency}
          selectedMatterId={this.props.selectedMatterId}
          visibleDocuments={this.props.visibleDocuments}
          subsetIDSEnabled={this.props.subsetIDSEnabled}
          updateMatter={this.props.updateMatter}
        />
      </Column>
    );

    const urgent = (
      <Column title={sortUrgent} style={{ width: '6em', textAlign: 'center' }}>
        <TodoUrgent updateMatter={this.props.updateMatter} />
      </Column>
    );

    const alternateId = (
      <Column title={sortAlternate}>
        <TodoField field='alternateId' />
      </Column>
    );

    const pairStatus = (
      <Column title={sortPairStatus} style={{ width: '4.5em', textAlign: 'center' }}>
        <TodoPairStatus
          pairStatus={this.props.pairStatus}
          features={this.props.features}
          updateMatter={this.props.updateMatter}
          updatePairStatus={this.props.updatePairStatus}
          updateMatterPairStatus={this.props.updateMatterPairStatus}
        />
      </Column>
    );

    return (
      <TableList
        id='todos-list'
        style={{ tableLayout: 'fixed' }}
        hover
        rows={rows}
        rowAs='todo'
        rowKey='id'
        selectedRow={this.props.selectedMatterId}
        onClickRow={this.onClickRow}
      >
        {file}
        {ids}
        {approved}
        {client}
        {matter}
        {alternateId}
        {cited}
        {uncited}
        {timing}
        {statement}
        {pairStatus}
        {ptoFee}
        {urgent}
      </TableList>
    );
  }

  renderEmpty() {
    return (
      <div id='todos-empty' style={{ paddingTop: '5px' }}>
        <span
          className='fa fa-sort-up'
          style={{
            position: 'absolute',
            fontSize: '40px',
            color: '#464545',
            marginLeft: '50px',
            marginTop: '-15px'
          }}
        />
        <div style={{
          backgroundColor: '#464545',
          width: '350px',
          padding: '15px',
          borderRadius: '3px'
        }}
        >
          No results found. Try adjusting the filters above.
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }

  renderBody() {
    const { itemsToRender, pageCount, activePage } = this.getRenderData();
    return (
      <div>
        {Boolean(this.props.todoList.size)
          ? this.renderTable(itemsToRender)
          : this.renderEmpty()}
        <div className='text-center'>
          {Boolean(pageCount) && (
            <TodosReports
              callApi={this.props.callApi}
              term={this.props.term}
              show={this.props.show}
              firmName={this.props.firmName}
              authToken={this.props.authToken}
              filterType={this.props.filterType}
              selectedClientId={this.props.selectedClientId}
              entities={this.props.entities}
              addNotification={this.props.addNotification}
              loadSyncOverlap={this.props.loadSyncOverlap}
            />
          )}
          <TodosMigration
            hasSelectedClient={!!this.props.selectedClientId}
            showUploadMigration={this.props.features.uploadMigration}
            uploadTwoWayMigration={this.props.uploadTwoWayMigration}
            uploadAutoGoldData={this.props.uploadAutoGoldData}
          />
          {this.props.features.limitedDocs && (
            <Button
              className='pull-left'
              style={{ marginRight: '15px' }}
              variant='primary'
              size='sm'
              onClick={this.onClickAssistant}
            >
              Assistant
            </Button>
          )}
          {Boolean(pageCount) && (
            <TodosPages
              pageCount={pageCount}
              currentPageSize={this.props.pageSize}
              activePage={activePage}
              onChangePage={this.props.selectPage}
              onChangePageSize={this.props.setPageSize}
            />
          )}
        </div>
      </div>
    );
  }

  onCloseUpload() {
    this.setState({
      upload: false
    });
  }

  renderUpload() {
    return this.state.upload && (
      <TodoUpload
        url={this.state.upload.url}
        todo={this.state.upload.todo}
        loginEmail={this.props.loginEmail}
        onCancel={this.onCloseUpload}
        onConfirm={this.onCloseUpload}
      />
    );
  }

  render() {
    return (
      <div style={{ paddingBottom: '10px' }}>
        {this.renderBody()}
        {this.renderUpload()}
      </div>
    );
  }
};
