import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { Overlay, Popover, Button, PopoverBody, PopoverHeader } from 'react-bootstrap';
import TodoRecord from '../../models/TodoRecord';

export default class TodoPairStatus extends Component {
  static propTypes = {
    todo: PropTypes.instanceOf(TodoRecord).isRequired,
    updateMatter: PropTypes.func.isRequired,
    updateMatterPairStatus: PropTypes.func.isRequired
  };

  static defaultProps = {
    todo: new TodoRecord()
  };

  static getDerivedStateFromProps(props, state) {
    if (props.todo.get('matterId') !== state.matterId) {
      return {
        matterId: props.todo.get('matterId'),
        status: null,
        date: null,
        private: null,
        failed: false,
        show: false
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {};
  }

  assignRef(iconRef) {
    this.setState({ iconRef });
  }

  preventHide() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  }

  hidePopover() {
    this.hideTimeout = setTimeout(() => this.setState({ show: false }), 300);
  }

  showPopover() {
    this.preventHide();

    this.setState({ show: true });

    if (this.state.status && !this.state.failed) {
      return;
    }

    this.props.updateMatterPairStatus(this.getMatterId()).then((matter) => {
      if (matter.patentCenterPairStatusDate) {
        this.setState({
          status: matter.patentCenterPairStatus,
          date: matter.patentCenterPairStatusDate,
          failed: false,
          private: true
        });
      } else if (matter.pairStatusDate) {
        this.setState({
          status: matter.pairStatus,
          date: matter.pairStatusDate,
          failed: false,
          private: true
        });
      } else {
        this.setState({
          failed: true
        });
      }
    });
  }

  getMatterId() {
    return this.props.todo.get('matterId');
  }

  getStatus() {
    if (this.state.failed) {
      return 'PAIR status not available';
    }
    if (!this.state.status) {
      return 'Checking...';
    }
    return `${this.state.status} - ${this.state.date}`;
  }

  getPopoverClass() {
    if (this.state.failed) {
      return 'pair-status-fail';
    }
    if (this.state.status) {
      return 'pair-status-success';
    }
  }

  getPopoverTitle() {
    if (this.state.private) {
      return 'PAIR Status';
    }
    if (this.state.status) {
      return 'Public PAIR Status';
    }
  }

  onClickClose() {
    this.props.updateMatter({
      id: this.props.todo.get('matterId'),
      matterClosed: 'ON'
    }, this.props.todo.get('matterNumber'));
  }

  shouldShowCloseButton() {
    return (
      !this.state.failed &&
      this.state.status &&
      /patented|abandoned/i.test(this.state.status) &&
      !this.props.todo.isClosed()
    );
  }

  renderCloseButton() {
    return (
      <>
        <hr />
        <Button key='close' variant='primary' size='sm' onClick={this.onClickClose}>
          Close Matter
        </Button>
      </>
    );
  }

  renderOverlay() {
    return (
      <Overlay
        placement='right'
        target={this.state.iconRef}
        show={this.state.show}
      >
        <Popover
          onMouseOver={this.preventHide}
          onMouseOut={this.hidePopover}
          className={this.getPopoverClass()}
          id={`pair-status-${this.getMatterId()}`}
        >
          <PopoverHeader>
            {this.getPopoverTitle()}
          </PopoverHeader>
          <PopoverBody>
            {this.getStatus()}
            {this.shouldShowCloseButton() && this.renderCloseButton()}
          </PopoverBody>
        </Popover>
      </Overlay>
    );
  }

  renderIcon() {
    return (
      <span
        ref={this.assignRef}
        onMouseOut={this.hidePopover}
        onMouseOver={this.showPopover}
        className='fa fa-info-circle'
      />
    );
  }

  render() {
    if (!this.props.todo.get('applicationNumber')) {
      return null;
    }

    return (
      <div>
        {this.renderIcon()}
        {this.renderOverlay()}
      </div>
    );
  }
};
